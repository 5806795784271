import React from "react"
import { Link, graphql } from "gatsby"
import AnchorLink from "react-anchor-link-smooth-scroll"

import Layout from "@components/layout"
import Seo from "@components/seo"

const PrivacyPolicy = ({ data }) => (
  <Layout>
    <Seo
      title="Privacy Policy"
      description={data.site.siteMetadata.description}
    />
    <div className="page-headline">
      <div className="container">
        <h1>Privacy Policy</h1>
        <h6 className="text-white-50">Last Updated August 30, 2018</h6>
      </div>
    </div>
    <div className="page-content bg-light small">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-9">
            <p>
              Thank you for choosing to be part of our community at IT
              Specialists, Inc. (“<strong>Company</strong>”, “
              <strong>we</strong>”, “<strong>us</strong>”, or “
              <strong>our</strong>”). We are committed to protecting your
              personal information and your right to privacy. If you have any
              questions or concerns about our policy, or our practices with
              regards to your personal information, please contact us at
              tknight@itspecialists.net.
            </p>
            <p>
              When you visit our website{" "}
              <Link to="/">http://www.itspecialists.net</Link>, Facebook
              application, and use our services, you trust us with your personal
              information. We take your privacy very seriously. In this privacy
              policy, we seek to explain to you in the clearest way possible
              what information we collect, how we use it and what rights you
              have in relation to it. We hope you take some time to read through
              it carefully, as it is important. If there are any terms in this
              privacy policy that you do not agree with, please discontinue use
              of our Sites or Apps and our services.
            </p>
            <p>
              This privacy policy applies to all information collected through
              our website (such as{" "}
              <Link to="/">http://www.itspecialists.net</Link>), Facebook
              application, ("<strong>Apps</strong>"), and/or any related
              services, sales, marketing or events (we refer to them
              collectively in this privacy policy as the "
              <strong>Services</strong>").
            </p>
            <p className="font-weight-bold">
              Please read this privacy policy carefully as it will help you make
              informed decisions about sharing your personal information with
              us.
            </p>
            <h4>TABLE OF CONTENTS</h4>
            <ol>
              <li>
                <AnchorLink offset="90" href="#infocollect">
                  WHAT INFORMATION DO WE COLLECT?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink offset="90" href="#infouse">
                  HOW DO WE USE YOUR INFORMATION?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink offset="90" href="#infoshare">
                  WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink offset="90" href="#cookies">
                  DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink offset="90" href="#inforetain">
                  HOW LONG DO WE KEEP YOUR INFORMATION?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink offset="90" href="#infominors">
                  DO WE COLLECT INFORMATION FROM MINORS?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink offset="90" href="#privacyrights">
                  WHAT ARE YOUR PRIVACY RIGHTS?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink offset="90" href="#DNT">
                  CONTROLS FOR DO-NOT-TRACK FEATURES
                </AnchorLink>
              </li>
              <li>
                <AnchorLink offset="90" href="#caresidents">
                  DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink offset="90" href="#policyupdates">
                  DO WE MAKE UPDATES TO THIS POLICY?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink offset="90" href="#contact">
                  HOW CAN YOU CONTACT US ABOUT THIS POLICY?
                </AnchorLink>
              </li>
            </ol>
            <h4 id="infocollect">1. WHAT INFORMATION DO WE COLLECT?</h4>
            <h6>Personal information you disclose to us</h6>
            <p className="font-italic">
              <span className="font-weight-bold">In Short:</span> We collect
              personal information that you provide to us.
            </p>
            <p>
              We collect personal information that you voluntarily provide to us
              when expressing an interest in obtaining information about us or
              our products and services, when participating in activities on the
              Services or Apps or otherwise contacting us.
            </p>
            <p>
              The personal information that we collect depends on the context of
              your interactions with us and the Services or Apps, the choices
              you make and the products and features you use. The personal
              information we collect can include the following:
            </p>
            <p>
              All personal information that you provide to us must be true,
              complete and accurate, and you must notify us of any changes to
              such personal information.
            </p>
            <h6>Information automatically collected</h6>
            <p className="font-italic">
              <span className="font-weight-bold">In Short:</span> Some
              information — such as IP address and/or browser and device
              characteristics — is collected automatically when you visit our
              Services or Apps.
            </p>
            <p>
              We automatically collect certain information when you visit, use
              or navigate the Services or Apps. This information does not reveal
              your specific identity (like your name or contact information) but
              may include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our Services or Apps and
              other technical information. This information is primarily needed
              to maintain the security and operation of our Services or Apps,
              and for our internal analytics and reporting purposes.
            </p>
            <p>
              Like many businesses, we also collect information through cookies
              and similar technologies.
            </p>
            <h6>Information collected through our Apps</h6>
            <p className="font-italic">
              <span className="font-weight-bold">In Short:</span> We may collect
              information regarding your and Facebook permissions when you use
              our apps.
            </p>
            <p>
              If you use our Apps, we may also collect the following
              information:
            </p>
            <ul>
              <li>
                Facebook Permissions. We by default access your{" "}
                <a
                  href="https://www.facebook.com/about/privacy/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="facebook"
                >
                  Facebook
                </a>{" "}
                basic account information, including your name, email, gender,
                birthday, current city, and profile picture URL, as well as
                other information that you choose to make public. We may also
                request access to other permissions related to your account,
                such as friends, checkins, and likes, and you may choose to
                grant or deny us access to each individual permission. For more
                information regarding Facebook permissions, refer to the{" "}
                <a
                  href="https://developers.facebook.com/docs/facebook-login/permissions"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="facebook permission"
                >
                  Facebook Permissions Reference
                </a>{" "}
                page.
              </li>
            </ul>
            <h4 id="infouse">2. HOW DO WE USE YOUR INFORMATION?</h4>
            <p className="font-italic">
              <span className="font-weight-bold">In Short:</span> We process
              your information for purposes based on legitimate business
              interests, the fulfillment of our contract with you, compliance
              with our legal obligations, and/or your consent.
            </p>
            <p>
              We use personal information collected via our Services or Apps for
              a variety of business purposes described below. We process your
              personal information for these purposes in reliance on our
              legitimate business interests, in order to enter into or perform a
              contract with you, with your consent, and/or for compliance with
              our legal obligations. We indicate the specific processing grounds
              we rely on next to each purpose listed below.
            </p>
            <p>We use the information we collect or receive:</p>
            <ul>
              <li>
                <strong>
                  To send you marketing and promotional communications.
                </strong>{" "}
                We and/or our third party marketing partners may use the
                personal information you send to us for our marketing purposes,
                if this is in accordance with your marketing preferences. You
                can opt-out of our marketing emails at any time (see the "
                <AnchorLink href="#privacyrights" offset="90">
                  WHAT ARE YOUR PRIVACY RIGHTS"
                </AnchorLink>{" "}
                below).
              </li>
              <li>
                <strong>To send administrative information to you.</strong> We
                may use your personal information to send you product, service
                and new feature information and/or information about changes to
                our terms, conditions, and policies.
              </li>
              <li>
                <strong>To post testimonials.</strong> We post testimonials on
                our Services or Apps that may contain personal information.
                Prior to posting a testimonial, we will obtain your consent to
                use your name and testimonial. If you wish to update, or delete
                your testimonial, please contact us at tknight@itspecialists.net
                and be sure to include your name, testimonial location, and
                contact information.
              </li>
              <li>
                <strong>To protect our Services.</strong> We may use your
                information as part of our efforts to keep our Services or Apps
                safe and secure (for example, for fraud monitoring and
                prevention).
              </li>
              <li>
                <strong>
                  To enforce our terms, conditions and policies for Business
                  Purposes, Legal Reasons and Contractual.
                </strong>
              </li>
              <li>
                <strong>To respond to legal requests and prevent harm.</strong>{" "}
                If we receive a subpoena or other legal request, we may need to
                inspect the data we hold to determine how to respond.
              </li>
              <li>
                <strong>For other Business Purposes.</strong> We may use your
                information for other Business Purposes, such as data analysis,
                identifying usage trends, determining the effectiveness of our
                promotional campaigns and to evaluate and improve our Services
                or Apps, products, marketing and your experience. We may use and
                store this information in aggregated and anonymized form so that
                it is not associated with individual end users and does not
                include personal information. We will not use identifiable
                personal information without your consent.
              </li>
            </ul>
            <h4 id="infoshare">
              3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
            </h4>
            <p className="font-italic">
              <span className="font-weight-bold">In Short:</span> In Short: We
              only share information with your consent, to comply with laws, to
              provide you with services, to protect your rights, or to fulfill
              business obligations.
            </p>
            <p>
              We may process or share data based on the following legal basis:
            </p>
            <ul>
              <li>
                <strong>Consent:</strong> We may process your data if you have
                given us specific consent to use your personal information in a
                specific purpose.
              </li>
              <li>
                <strong>Legitimate Interests:</strong> We may process your data
                when it is reasonably necessary to achieve our legitimate
                business interests.
              </li>
              <li>
                <strong>Performance of a Contract:</strong> Where we have
                entered into a contract with you, we may process your personal
                information to fulfill the terms of our contract.
              </li>
              <li>
                <strong>Legal Obligations:</strong> We may disclose your
                information where we are legally required to do so in order to
                comply with applicable law, governmental requests, a judicial
                proceeding, court order, or legal process, such as in response
                to a court order or a subpoena (including in response to public
                authorities to meet national security or law enforcement
                requirements).
              </li>
              <li>
                <strong>Vital Interests:</strong> We may disclose your
                information where we believe it is necessary to investigate,
                prevent, or take action regarding potential violations of our
                policies, suspected fraud, situations involving potential
                threats to the safety of any person and illegal activities, or
                as evidence in litigation in which we are involved.
              </li>
            </ul>
            <p>
              More specifically, we may need to process your data or share your
              personal information in the following situations:
            </p>
            <ul>
              <li>
                <strong>
                  Vendors, Consultants and Other Third-Party Service Providers.
                </strong>{" "}
                We may share your data with third party vendors, service
                providers, contractors or agents who perform services for us or
                on our behalf and require access to such information to do that
                work. Examples include: payment processing, data analysis, email
                delivery, hosting services, customer service and marketing
                efforts. We may allow selected third parties to use tracking
                technology on the Services or Apps, which will enable them to
                collect data about how you interact with the Services or Apps
                over time. This information may be used to, among other things,
                analyze and track data, determine the popularity of certain
                content and better understand online activity. Unless described
                in this Policy, we do not share, sell, rent or trade any of your
                information with third parties for their promotional purposes.
              </li>
              <li>
                <strong>Business Transfers.</strong> We may share or transfer
                your information in connection with, or during negotiations of,
                any merger, sale of company assets, financing, or acquisition of
                all or a portion of our business to another company.
              </li>
              <li>
                <strong>Business Partners.</strong> We may share your
                information with our business partners to offer you certain
                products, services or promotions.
              </li>
            </ul>
            <h4 id="cookies">
              4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </h4>
            <p className="font-italic">
              <span className="font-weight-bold">In Short:</span> We may use
              cookies and other tracking technologies to collect and store your
              information.
            </p>
            <p>
              We may use cookies and similar tracking technologies (like web
              beacons and pixels) to access or store information. Specific
              information about how we use such technologies and how you can
              refuse certain cookies is set out in our Cookie Policy.
            </p>
            <h4 id="inforetain">5. HOW LONG DO WE KEEP YOUR INFORMATION?</h4>
            <p className="font-italic">
              <span className="font-weight-bold">In Short:</span> We keep your
              information for as long as necessary to fulfill the purposes
              outlined in this privacy policy unless otherwise required by law.
            </p>
            <p>
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy policy, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting or other legal requirements). No purpose in this
              policy will require us keeping your personal information for
              longer than 2 years.
            </p>
            <p>
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymize it, or,
              if this is not possible (for example, because your personal
              information has been stored in backup archives), then we will
              securely store your personal information and isolate it from any
              further processing until deletion is possible.
            </p>
            <h4 id="infominors">6. DO WE COLLECT INFORMATION FROM MINORS?</h4>
            <p className="font-italic">
              <span className="font-weight-bold">In Short:</span> We do not
              knowingly collect data from or market to children under 18 years
              of age.
            </p>
            <p>
              We do not knowingly solicit data from or market to children under
              18 years of age. By using the Services or Apps, you represent that
              you are at least 18 or that you are the parent or guardian of such
              a minor and consent to such minor dependent’s use of the Services
              or Apps. If we learn that personal information from users less
              than 18 years of age has been collected, we will deactivate the
              account and take reasonable measures to promptly delete such data
              from our records. If you become aware of any data we have
              collected from children under age 18, please contact us at
              tknight@itspecialists.net.
            </p>
            <h4 id="privacyrights">7. WHAT ARE YOUR PRIVACY RIGHTS?</h4>
            <p className="font-italic">
              <span className="font-weight-bold">In Short:</span> You may
              review, change, or terminate your account at any time.
            </p>
            <p>
              If you are resident in the European Economic Area and you believe
              we are unlawfully processing your personal information, you also
              have the right to complain to your local data protection
              supervisory authority. You can find their contact details here:{" "}
              <a
                href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
              </a>
              .
            </p>
            <p>
              <u>
                <strong>Cookies and similar technologies</strong>
              </u>
              : Most Web browsers are set to accept cookies by default. If you
              prefer, you can usually choose to set your browser to remove
              cookies and to reject cookies. If you choose to remove cookies or
              reject cookies, this could affect certain features or services of
              our Services or Apps. To opt-out of interest-based advertising by
              advertisers on our Services or Apps visit{" "}
              <a
                href="http://www.aboutads.info/choices/"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://www.aboutads.info/choices/
              </a>
              .
            </p>
            <h4 id="DNT">8. CONTROLS FOR DO-NOT-TRACK FEATURES</h4>
            <p>
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track (“DNT”) feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. No
              uniform technology standard for recognizing and implementing DNT
              signals has been finalized. As such, we do not currently respond
              to DNT browser signals or any other mechanism that automatically
              communicates your choice not to be tracked online. If a standard
              for online tracking is adopted that we must follow in the future,
              we will inform you about that practice in a revised version of
              this privacy policy.
            </p>
            <h4 id="caresidents">
              9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </h4>
            <p className="font-italic">
              <span className="font-weight-bold">In Short:</span> Yes, if you
              are a resident of California, you are granted specific rights
              regarding access to your personal information.
            </p>
            <p>
              California Civil Code Section 1798.83, also known as the “Shine
              The Light” law, permits our users who are California residents to
              request and obtain from us, once a year and free of charge,
              information about categories of personal information (if any) we
              disclosed to third parties for direct marketing purposes and the
              names and addresses of all third parties with which we shared
              personal information in the immediately preceding calendar year.
              If you are a California resident and would like to make such a
              request, please submit your request in writing to us using the
              contact information provided below.
            </p>
            <p>
              If you are under 18 years of age, reside in California, and have a
              registered account with the Services or Apps, you have the right
              to request removal of unwanted data that you publicly post on the
              Services or Apps. To request removal of such data, please contact
              us using the contact information provided below, and include the
              email address associated with your account and a statement that
              you reside in California. We will make sure the data is not
              publicly displayed on the Services or Apps, but please be aware
              that the data may not be completely or comprehensively removed
              from our systems.
            </p>
            <h4 id="policyupdates">10. DO WE MAKE UPDATES TO THIS POLICY?</h4>
            <p className="font-italic">
              <span className="font-weight-bold">In Short:</span> Yes, we will
              update this policy as necessary to stay compliant with relevant
              laws.
            </p>
            <p>
              We may update this privacy policy from time to time. The updated
              version will be indicated by an updated “Revised” date and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy policy, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy policy frequently to be informed of how we are
              protecting your information.
            </p>
            <h4 id="contact">11. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</h4>
            <p>
              If you have questions or comments about this policy, you may
              contact our Data Protection Officer (DPO), Thomas Knight, by email
              at tknight@itspecialists.net, by phone at 719-531-9309, or by post
              to:
            </p>
            <p className="font-weight-bold">
              {" "}
              IT Specialists, Inc.
              <br />
              Thomas Knight
              <br />
              6625 Delmonico Drive
              <br />
              Colorado Springs, CO 80919
              <br />
              United States
            </p>
            <p className="font-weight-bold">
              HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </p>
            <p>
              Based on the laws of some countries, you may have the right to
              request access to the personal information we collect from you,
              change that information, or delete it in some circumstances. To
              request to review, update, or delete your personal information,
              please submit a request form by clicking{" "}
              <a
                href="https://app.termly.io/notify/c6fbc87b-1426-465b-9bf5-4d76a2c2c7b5"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              . We will respond to your request within 30 days.
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default PrivacyPolicy

export const privacyPolicyPageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
